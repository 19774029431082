exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accuracy-matters-js": () => import("./../../../src/pages/accuracy-matters.js" /* webpackChunkName: "component---src-pages-accuracy-matters-js" */),
  "component---src-pages-automations-js": () => import("./../../../src/pages/automations.js" /* webpackChunkName: "component---src-pages-automations-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-demo-thanks-js": () => import("./../../../src/pages/demo-thanks.js" /* webpackChunkName: "component---src-pages-demo-thanks-js" */),
  "component---src-pages-developer-productivity-metrics-guide-js": () => import("./../../../src/pages/developer-productivity-metrics-guide.js" /* webpackChunkName: "component---src-pages-developer-productivity-metrics-guide-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-disclosure-js": () => import("./../../../src/pages/disclosure.js" /* webpackChunkName: "component---src-pages-disclosure-js" */),
  "component---src-pages-diversity-js": () => import("./../../../src/pages/diversity.js" /* webpackChunkName: "component---src-pages-diversity-js" */),
  "component---src-pages-dora-js": () => import("./../../../src/pages/dora.js" /* webpackChunkName: "component---src-pages-dora-js" */),
  "component---src-pages-efficiency-primer-js": () => import("./../../../src/pages/efficiency-primer.js" /* webpackChunkName: "component---src-pages-efficiency-primer-js" */),
  "component---src-pages-executives-js": () => import("./../../../src/pages/executives.js" /* webpackChunkName: "component---src-pages-executives-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-managers-js": () => import("./../../../src/pages/managers.js" /* webpackChunkName: "component---src-pages-managers-js" */),
  "component---src-pages-milestone-checklist-js": () => import("./../../../src/pages/milestone-checklist.js" /* webpackChunkName: "component---src-pages-milestone-checklist-js" */),
  "component---src-pages-one-hundred-deploys-a-day-js": () => import("./../../../src/pages/one-hundred-deploys-a-day.js" /* webpackChunkName: "component---src-pages-one-hundred-deploys-a-day-js" */),
  "component---src-pages-preview-post-js": () => import("./../../../src/pages/preview-post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-request-for-personal-information-js": () => import("./../../../src/pages/request-for-personal-information.js" /* webpackChunkName: "component---src-pages-request-for-personal-information-js" */),
  "component---src-pages-soc-2-compliance-js": () => import("./../../../src/pages/soc2-compliance.js" /* webpackChunkName: "component---src-pages-soc-2-compliance-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-trust-js": () => import("./../../../src/pages/trust.js" /* webpackChunkName: "component---src-pages-trust-js" */),
  "component---src-templates-category-template-index-js": () => import("./../../../src/templates/CategoryTemplate/index.js" /* webpackChunkName: "component---src-templates-category-template-index-js" */),
  "component---src-templates-post-template-index-js": () => import("./../../../src/templates/PostTemplate/index.js" /* webpackChunkName: "component---src-templates-post-template-index-js" */),
  "component---src-templates-resources-template-index-js": () => import("./../../../src/templates/ResourcesTemplate/index.js" /* webpackChunkName: "component---src-templates-resources-template-index-js" */),
  "component---src-templates-sleuth-tv-live-template-index-js": () => import("./../../../src/templates/SleuthTvLiveTemplate/index.js" /* webpackChunkName: "component---src-templates-sleuth-tv-live-template-index-js" */),
  "component---src-templates-subcategory-template-index-js": () => import("./../../../src/templates/SubcategoryTemplate/index.js" /* webpackChunkName: "component---src-templates-subcategory-template-index-js" */)
}

